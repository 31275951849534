<template>
    <div>
        <form-container
            id="feedbackTextArea"
            ref="feedbackTextArea"
            @on-submit="onSubmit"
        >
            <form-text-area
                name="feedbackTextArea"
                ref="feedbackTextArea"
                class="border-primary"
                placeholder="What is your feedback?"
                validation="required|min:2"
                v-model="feedbackText"
                :rows="3"
            />
            <form-button
                type="submit"
                class="mt-2"
                event-name="click_button_feedback_text_area"
            >
                Continue
            </form-button>
        </form-container>
    </div>
</template>

<script>
    import FormContainer from '@/components/base/FormContainer'
    import { i18n } from '@/utils/i18n'
    import { sendUserFeedbackEmail } from '@/services/api'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import FormTextArea from '@/components/base/FormTextArea'
    import FormButton from '@/components/base/FormButton'

    export default {
        name: 'FeedbackTextArea',
        components: {
            'form-button': FormButton,
            'form-text-area': FormTextArea,
            'form-container': FormContainer,
        },
        data: function () {
            return {
                feedbackText: '',
                submitting: false,
                successfullySubmitted: false,
            }
        },
        methods: {
            onSubmit: async function () {
                this.submitting = true
                try {
                    const isValid = await this.$refs.feedbackTextArea.$refs.observer.validate()
                    if (isValid) {
                        await sendUserFeedbackEmail(this.feedbackText, Number(appSessionStorage.getItem(sessionStorageKey.applicantId)))
                        this.$logEvent('event_successfully_sent_feedback', { feedback: this.feedbackText })
                        this.successfullySubmitted = true
                    } else {
                        // Note that the call to validate() above will display an error message
                        // if the form is invalid.
                        this.$logEvent('event_failed_to_send_feedback', {
                            error: `invalid-text: ${this.feedbackText}`,
                        })
                    }
                } catch (e) {
                    console.log('e', e)
                    this.$refs.feedbackTextArea.applyError(i18n.t('global.errors.generic'))
                    logger.error(`Failed to send feedback message: ${this.feedbackText}`, null /* event */, e)
                    this.$logEvent('event_failed_to_send_feedback', {
                        error: `error: ${e}`,
                    })
                } finally {
                    this.$emit('feedback-sent')
                    this.submitting = false
                }
            },
        },
    }
</script>

<style scoped></style>
