<template>
    <div ref="searchSupportContainer">
        <modal
            :open="modalOpen"
            @on-modal-close-click="closeModal"
            title="Send Feedback"
        >
            <feedback-text-area
                v-if="!feedbackSubmitted"
                @feedback-sent="feedbackSubmitted = true"
            />
            <div v-else>
                <p>Thank you for your feedback. It really helps us build a better product for you. We will reach back shortly.</p>
                <form-button @click="onModalContinue">
                    Continue
                </form-button>
            </div>
        </modal>
        <form-field
            name="questions"
            v-model="searchQuery"
            :label="inputPlaceholder"
            placeholder="Search..."
            @click="handleIntentClick"
            @input="searchSupport"
            @focus="setSearchFocus(true)"
            @blur="setSearchBlur(false)"
            @keydown.enter.native="onSelectedArticle"
            autocomplete="off"
        />
        <div class="question-results-container position-relative w-100 mb-1">
            <div
                v-if="showSearchDropdown"
                class="results"
            >
                <ul class="list-group position-absolute w-100">
                    <li
                        v-for="(result, index) in searchResults"
                        :data-testid="'question-autocomplete-prediction-' + index + '-option'"
                        @click="onSelectedArticle(index)"
                        @keydown.enter="onSelectedArticle()"
                        class="list-group-item px-2"
                        :class="{ selected: index === selectedIndex }"
                        :key="`${index}`"
                    >
                        <button class="text-decoration-none d-block border-0 bg-transparent fw-normal px-0 text-start">
                            <span :class="{ 'fw-bold': !excludeSnippets }">{{ result.title }}</span><br>
                            <span
                                v-if="!excludeSnippets"
                                v-html="result.snippet"
                            />
                        </button>
                    </li>

                    <!-- TODO: make this a model instead of zendesk -->
                    <li
                        v-if="showFeedbackButton"
                        class="list-group-item px-2"
                    >
                        <button
                            @click="openModal"
                            class="text-decoration-none border-0 fw-normal bg-transparent px-0"
                        >
                            Send Feedback ➝
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div
            v-if="inlineArticle"
            ref="inlineArticleContainer"
            class="article-spacer"
        >
            <div v-if="inlineArticleObject">
                <div class="fw-bold mb-1">
                    {{ inlineArticleObject.title }}
                </div>
                <div
                    v-if="inlineArticleHasLongBody && !inlineArticleExpanded"
                    class="text-muted"
                >
                    <div
                        class="mb-1"
                        v-html="inlineArticleObject.snippet"
                    />
                    <button
                        class="text-primary border-0 fw-normal bg-transparent p-0 text-decoration-underline"
                        @click="expandInlineArticle"
                    >
                        Read More...
                    </button>
                </div>
                <div
                    v-else
                    class="text-muted"
                    v-html="inlineArticleObject.body"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import FormField from '@/components/base/FormField'
    import zendeskMixin from '@/mixins/zendeskMixin'
    import { logger } from '@/utils/logger'
    import Modal from '@/components/modal/Modal'
    import FeedbackTextArea from '@/components/base/FeedbackTextArea'
    import FormButton from '@/components/base/FormButton'

    export default {
        name: 'FormFieldSearchSupport',
        components: { FormButton, FeedbackTextArea, Modal, FormField },
        mixins: [zendeskMixin],
        props: {
            inputPlaceholder: {
                type: String,
                default: 'Search',
            },
            inputMaxEntries: {
                type: Number,
                default: 5,
            },
            excludeSnippets: {
                type: Boolean,
                default: false,
            },
            inlineArticle: {
                type: Boolean,
                default: false,
            },
            showFeedbackButton: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                searchQuery: '',
                searchResults: [],
                hasFocus: false,
                inlineArticleObject: null,
                inlineArticleExpanded: false,
                searchTitleForEvent: '',
                modalOpen: false,
                feedbackSubmitted: false,
                selectedIndex: 0,
            }
        },
        computed: {
            showSearchDropdown() {
                return this.hasFocus && this.searchQuery.length > 2
            },
            inlineArticleHasLongBody() {
                // This is a crude heuristic.  We're making an educated guess that articles with
                // html of length 500+ will be too long to render. More sophisticated approaches exist
                // but they come at the cost of performance and complexity
                return this.inlineArticleObject && this.inlineArticleObject.body.length > 500
            },
        },
        updated: function () {
            // Hack to maintain YT videos aspect ratio. The width/height of the video's iframe is hard coded.
            // We need to set the width to 100% and use a padding-bottom trick to make sure the video's height
            // adjusts accordingly as the width of the video grows.
            this.$nextTick(function () {
                if (this.$refs.inlineArticleContainer) {
                    const youtubeIframes = this.$refs.inlineArticleContainer.querySelectorAll(`iframe[src*='youtube']`)

                    for (const youtubeIframe of youtubeIframes) {
                        const { width, height } = youtubeIframe.getBoundingClientRect()

                        const aspectRatio = width / height

                        youtubeIframe.style.width = '100%'
                        youtubeIframe.style.height = 'auto'
                        youtubeIframe.style.paddingBottom = `${aspectRatio * 100}%`
                    }
                }
            })
        },
        beforeMount() {
            // Start listening for keydown events to handle moving the selected entry up and down
            window.addEventListener('keydown', this.handleKeydown, null)
        },
        beforeDestroy() {
            // Remove listener before leaving
            window.removeEventListener('keydown', this.handleKeydown)
        },
        methods: {
            async searchSupport() {
                if (this.searchQuery === '') {
                    this.searchResults = []
                    return
                }

                const result = await fetch(`https://support.aven.com/api/v2/help_center/articles/search?query=${encodeURIComponent(this.searchQuery)}&per_page=6`)

                const resultObject = await result.json()

                const formatSnippet = (snippet) => {
                    const trimmed = snippet.trim()

                    // Only add 2 periods if snippet already ends with one period
                    const suffix = trimmed.endsWith('.') ? '..' : '...'

                    return trimmed + suffix
                }

                this.searchResults = resultObject.results
                    .map((o) => ({
                        id: o.id,
                        title: o.title,
                        snippet: this.inlineArticle ? o.snippet : formatSnippet(o.snippet),
                        url: o.html_url,
                        body: o.body,
                    }))
                    .slice(0, this.inputMaxEntries)
            },
            onSelectedArticle(index) {
                try {
                    if (index > 0) {
                        // only necessary for on-clicks, keydown are handled differently below
                        this.selectedIndex = index
                    }
                    if (this.inlineArticle) {
                        logger.info(`Displaying inline article: ${JSON.stringify(this.searchResults[this.selectedIndex])}`)
                        console.log(this.searchResults[this.selectedIndex].body.length)
                        const dropdownResults = this.searchResults.map((result) => result.title)
                        this.$logEvent('click_search_query_inline_list_item', { searchQuery: this.searchQuery, searchResultTitle: this.searchResults[this.selectedIndex]?.title, dropdownResults })
                        this.inlineArticleObject = this.searchResults[this.selectedIndex]
                        this.inlineArticleExpanded = false
                        this.searchTitleForEvent = this.searchResults[this.selectedIndex]?.title
                    } else {
                        this.$logEvent('click_search_query_list_item', { searchQuery: this.searchQuery, searchResultTitle: this.searchResults[this.selectedIndex]?.title })
                        this.$emit('article-clicked', this.searchResults[this.selectedIndex].id)
                    }
                    this.searchQuery = ''
                    this.selectedIndex = 0
                } catch (e) {
                    logger.error('Could not open article on /education', e)
                }
            },
            setSearchFocus(shouldFocus) {
                if (shouldFocus) {
                    // focus immediately
                    this.hasFocus = shouldFocus
                    window.scrollTo({
                        top: this.$refs.searchSupportContainer.getBoundingClientRect().top + window.scrollY - 65,
                    })
                }
            },
            setSearchBlur(shouldFocus) {
                // workaround to allow click events to bubble up before disappearing
                // also send searchResultTitle if it exists ("use clicked on it")
                setTimeout(() => {
                    this.$logEvent('event_blur_intent_search_support_query', { searchQuery: this.searchQuery, searchResultTitle: this.searchTitleForEvent })
                    this.searchTitleForEvent = ''
                    this.hasFocus = shouldFocus
                }, 500)
            },
            handleKeydown(e) {
                switch (e.keyCode) {
                    // If UP Arrow -> move selected item up one or loop to bottom of the list
                    case 38:
                        this.selectedIndex > 0 ? this.selectedIndex-- : (this.selectedIndex = this.inputMaxEntries - 1)
                        break

                    // If DOWN Arrow -> move selected item down one or loop to beging of the list
                    case 40:
                        this.selectedIndex < this.inputMaxEntries - 1 ? this.selectedIndex++ : (this.selectedIndex = 0)
                        break
                }
            },
            handleIntentClick() {
                this.$logEvent('click_input_intent_search_support')
            },
            expandInlineArticle() {
                this.inlineArticleExpanded = true
            },
            openModal: function () {
                this.$logEvent('click_button_select_open_modal_send_feedback')
                this.modalOpen = true
            },
            closeModal: function () {
                this.$logEvent('click_button_close_modal_send_feedback')
                this.modalOpen = false
            },
            onModalContinue: async function () {
                this.$logEvent('click_button_continue_modal_send_feedback')
                this.modalOpen = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    .list-group-item {
        &:hover {
            cursor: pointer;
            background-color: $gray-100;
        }
        &.selected {
            cursor: pointer;
            background-color: $blue;
            button {
                color: white !important;
            }
        }
    }
</style>
